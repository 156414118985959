// CDN Server
import _FileApi from "./Cdn/File.api";
import _CsvApi from "./Cdn/Csv.api";

// Auth Server
import _GroupApi from "./Auth/Group.api";
import _OauthApi from "./Auth/Oauth.api";
import _PermissionApi from "./Auth/Permission.api";
import _ResourceApi from "./Auth/Resource.api";
import _RoleApi from "./Auth/Role.api";
import _ScopeApi from "./Auth/Scope.api";
import _UserApi from "./Auth/User.api";
import _WorkspaceApi from "./Auth/Workspace.api";
import _OrganizationApi from "./Auth/Organization.api";
import _OrganogramApi from "./Auth/Organogram.api";
import _EmployeeApi from "./Auth/Employee.api";
import _RequestAccessApi from "./Auth/RequestAccess.api";
import _ApplicantProfileApi from "./Auth/ApplicantProfile.api";

// Core Server
import _NotificationApi from "./Util/Notification.api";
import _LookupApi from "./Core/Lookup.api";
import _LocationApi from "./Core/Location.api";
import _PostApi from "./Core/Post.api";
import _OptionApi from "./Core/Option.api";
import _TemplateApi from "./Core/Template.api";
import _HelpApi from "./Core/Help.api";
import _ActivityLogApi from "./Util/ActivityLog.api";
import _AuditLogApi from "./Util/AuditLog.api";
import _ExportScmApi from "./Core/ExportScm.api";
import _ImportScmApi from "./Core/ImportScm.api";
import _FackApi from "./Core/Fack.api";
import _OrganizationCategoryApi from "./Core/OrganizationCategory.api";
import _DepartmentApi from "./Core/Department.api";
import _ApiResourceApi from "./Core/ApiResource.api";
import _FinancialYearApi from "./Core/FinancialYear.api";
import _EnumApi from "./Core/Enum.api";
import _BankApi from "./Core/Bank.api";
import _BankBranchApi from "./Core/BankBranch.api";
import _DesignationApi from "./Auth/Designation.api";
import _DepartmentSubjectApi from "./Core/DepartmentSubject.api";
import _FacultySchoolApi from "./Core/FacultySchool.api";
import _InstituteOrganizationApi from "./Core/InstituteOrganization.api";
import _AutomationRuleApi from "./Core/AutomationRule.api";
import _WorkflowApprovalApi from "./Core/WorkflowApproval.api";
import _WorkflowBulkTransitionApi from "./Core/WorkflowBulkTransition.api";
import _WorkflowGroupTransitionApi from "./Core/WorkflowGroupTransition.api";
import _WorkflowStepApi from "./Core/WorkflowStep.api";
import _WorkflowTransitionApi from "./Core/WorkflowTransition.api";
import _workflowTransitionAssignment from "./Core/workflowTransitionAssignment.api";
import _ServiceMappingApi from "./Core/ServiceMapping.api";
import _AttachmentTypeApi from "./Core/AttachmentType.api";
import _PortApi from "./Core/Port.api";
import _ComponentApi from "./Core/Component.api";
import _PaymentHistoryApi from "./Core/PaymentHistory.api";

// CMS Server
import _PageApi from "./Cms/Page.api";
import _BlocksApi from "./Cms/Blocks.api";
import _MenuApi from "./Cms/Menu.api";
import _ServicesApi from "./Cms/Services.api";
import _MediaApi from "./Cms/Media.api";
import _CategoriesApi from "./Cms/Categories.api";

// Grant Server
import _ItemTypeApi from "./Grant/ItemType.api";
import _ApplicationTypeApi from "./Grant/ApplicationType.api";
// import _CircularApi from "./Grant/Circular.api";
// import _CommitteeApi from "./Grant/Committee.api";

// License
import _LicenseApplicationApi from "./License/LicenseApplicationApi.api";
import _DashboardApi from "./License/Dashboard.api";
import _ProductApi from "./License/Product.api";
import _LicenseRenewalFeeApi from "./License/LicenseRenewalFee.api";
import _ManufacturerApi from "./License/Manufacturer.api";
import _SupplierApi from "./License/Supplier.api";
import _FacilityPracticeApi from "./License/FacilityPractice.api";
import _ImportExportPermitApplicationApi from "./License/ImportExportPermitApplication.api";
import _ClassELicenseApi from "./License/ClassELicense.api";
import _ClassAApplicationApi from "./License/ClassAApplication.api";
import _ClassALicenseApi from "./License/ClassALicense.api";
import _ClassCLicenseApplicationApi from "./License/ClassCLicense.api";
import _ScienceClubApi from "./License/ScienceClub.api";
import _RcoApplicationApi from "./License/RcoApplicationApi.api";
import _ImportExportPermitApi from "./License/ImportExportPermit.api";
import _RcoLicenseApi from "./License/RcoLicense.api";
import _ReportLicenseApi from "./License/ReportLicense.api";
import _RcoAnnualReportApi from "./License/RcoAnnualReportApi.api";
import _CLicenseApi from "./License/CLicense.api";
import _ELicenseApi from "./License/ELicense.api";
import _NocApplicationApi from "./License/NocApplicationApi.api";
import _NocApplicationLicensesApi from "./License/NocApplicationLicensesApi.api";
import _ServiceTemplatesApi from "./License/ServiceTemplates.api";
import _LicensePermitVerificationApi from "./License/LicensePermitVerification.api";
import _InspectionScheduleApi from "./License/InspectionSchedule.api";
import _InspectionPlanApi from "./License/InspectionPlan.api";
import _InspectionApi from "./License/Inspection.api";
import _LicenseSurrenderApi from "./License/LicenseSurrenderApi.api";
import _ClassDApplicationApi from "./License/ClassDApplication.api";
import _ClassDLicenseApi from "./License/ClassDLicense.api";
import _ApplicationInspectionApi from "./License/ApplicationInspection.api";
import _ClassCRadiatingEquipmentDetail from "./License/ClassCRadiatingEquipmentDetail.api";
import _LicensePermitApi from "./License/LicensePermit.api";

// CDN Server
export const FileApi = new _FileApi();
export const CsvApi = new _CsvApi();

// Auth Server
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const PermissionApi = new _PermissionApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const OrganizationApi = new _OrganizationApi();
export const OrganogramApi = new _OrganogramApi();
export const EmployeeApi = new _EmployeeApi();
export const RequestAccessApi = new _RequestAccessApi();
export const ApplicantProfileApi = new _ApplicantProfileApi();

// Core Server
export const FackApi = new _FackApi();
export const NotificationApi = new _NotificationApi();
export const LookupApi = new _LookupApi();
export const LocationApi = new _LocationApi();
export const PostApi = new _PostApi();
export const OptionApi = new _OptionApi();
export const TemplateApi = new _TemplateApi();
export const HelpApi = new _HelpApi();
export const ActivityLogApi = new _ActivityLogApi();
export const AuditLogApi = new _AuditLogApi();
export const ExportScmApi = new _ExportScmApi();
export const ImportScmApi = new _ImportScmApi();
export const OrganizationCategoryApi = new _OrganizationCategoryApi();
export const DepartmentApi = new _DepartmentApi();
export const ApiResourceApi = new _ApiResourceApi();
export const FinancialYearApi = new _FinancialYearApi();
export const EnumApi = new _EnumApi();
export const BankApi = new _BankApi();
export const BankBranchApi = new _BankBranchApi();
export const DesignationApi = new _DesignationApi();
export const DepartmentSubjectApi = new _DepartmentSubjectApi();
export const FacultySchoolApi = new _FacultySchoolApi();
export const InstituteOrganizationApi = new _InstituteOrganizationApi();
export const AutomationRuleApi = new _AutomationRuleApi();
export const WorkflowApprovalApi = new _WorkflowApprovalApi();
export const WorkflowBulkTransitionApi = new _WorkflowBulkTransitionApi();
export const WorkflowGroupTransitionApi = new _WorkflowGroupTransitionApi();
export const WorkflowStepApi = new _WorkflowStepApi();
export const WorkflowTransitionApi = new _WorkflowTransitionApi();
export const workflowTransitionAssignment = new _workflowTransitionAssignment();
export const ServiceMappingApi = new _ServiceMappingApi();
export const AttachmentTypeApi = new _AttachmentTypeApi();
export const PortApi = new _PortApi();
export const ComponentApi = new _ComponentApi();
export const PaymentHistoryApi = new _PaymentHistoryApi();

// License
export const DashboardApi = new _DashboardApi();
export const ProductApi = new _ProductApi();
export const LicenseRenewalFeeApi = new _LicenseRenewalFeeApi();
export const ManufacturerApi = new _ManufacturerApi();
export const SupplierApi = new _SupplierApi();
export const FacilityPracticeApi = new _FacilityPracticeApi();
export const ImportExportPermitApplicationApi = new _ImportExportPermitApplicationApi();
export const ClassELicenseApi = new _ClassELicenseApi();
export const ClassAApplicationApi = new _ClassAApplicationApi();
export const ClassALicenseApi = new _ClassALicenseApi();
export const ClassCLicenseApplicationApi = new _ClassCLicenseApplicationApi();
export const LicenseApplicationApi = new _LicenseApplicationApi();
export const ScienceClubApi = new _ScienceClubApi();
export const RcoApplicationApi = new _RcoApplicationApi();
export const ImportExportPermitApi = new _ImportExportPermitApi();
export const RcoLicenseApi = new _RcoLicenseApi();
export const RcoAnnualReportApi = new _RcoAnnualReportApi();
export const CLicenseApi = new _CLicenseApi();
export const ELicenseApi = new _ELicenseApi();
export const NocApplicationApi = new _NocApplicationApi();
export const NocApplicationLicensesApi = new _NocApplicationLicensesApi();
export const ServiceTemplatesApi = new _ServiceTemplatesApi();
export const LicensePermitVerificationApi = new _LicensePermitVerificationApi();
export const InspectionScheduleApi = new _InspectionScheduleApi();
export const InspectionPlanApi = new _InspectionPlanApi();
export const InspectionApi = new _InspectionApi();
export const LicenseSurrenderApi = new _LicenseSurrenderApi();
export const ClassDApplicationApi = new _ClassDApplicationApi();
export const ClassDLicenseApi = new _ClassDLicenseApi();
export const ApplicationInspectionApi = new _ApplicationInspectionApi();
export const ClassCRadiatingEquipmentDetail = new _ClassCRadiatingEquipmentDetail();
export const LicensePermitApi = new _LicensePermitApi();

// CMS Server
export const PageApi = new _PageApi();
export const BlocksApi = new _BlocksApi();
export const MenuApi = new _MenuApi();
export const ServicesApi = new _ServicesApi();
export const MediaApi = new _MediaApi();
export const CategoriesApi = new _CategoriesApi();
export const ServiceApi = new _ServicesApi();

//Grant Server
export const ItemTypeApi = new _ItemTypeApi();
export const ApplicationTypeApi = new _ApplicationTypeApi();
// export const CircularApi = new _CircularApi();
// export const CommitteeApi = new _CommitteeApi();

//LICENSE REPORT

export const ReportLicenseApi = new _ReportLicenseApi();
