export const dateFormat = 'YYYY-MM-DD'; // 1971-12-16.
export const DATE_FORMAT_DATABASE = 'YYYY-MM-DD'; // 1971-12-16.
export const DATE_TIME_FORMAT_DATABASE = 'YYYY-MM-DD HH:mm:ss'; // 1971-12-16 24:00:00.
export const YEAR_FORMAT_DATABASE = 'YYYY'; // 1971.
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DATE_PICKER_DATE_FORMAT = 'DD-MMM-YYYY'; // 16-Dec-1971.
export const DATE_PICKER_DATE_FORMAT_A = 'DD-MM-YYYY'; // 16-12-1971.
export const DATE_PICKER_DATE_TIME_FORMAT = 'DD-MMM-YYYY h:mm a'; // ⛔ 16-Dec-1971 9:00 am.
export const DATE_PICKER_MONTH_FORMAT = 'YYYY-MM'; // 1971-12.
export const DATE_PICKER_TIME_FORMAT = 'HH:mm:ss'; // 24:00:00.
export const DATE_PICKER_YEAR_FORMAT = 'YYYY'; // 1971.
export const HUMAN_DATE_FORMAT = 'DD MMM YYYY'; // 16 Dec 1971.
export const HUMAN_DATETIME_FORMAT = "DD MMM YYYY hh:mm A"; // 16 Dec 1971 12:00 AM.

// Timers
export const INTERVAL_ONE_SECOND = 1000;
export const INTERVAL_TEN_SECONDS = (10 * 1000);
export const INTERVAL_THIRTY_SECONDS = (30 * 1000);

export const INTERVAL_ONE_MINUTE = (60 * 1000);
export const INTERVAL_TWO_MINUTES = (2 * 60 * 1000);
export const INTERVAL_FIVE_MINUTES = (5 * 60 * 1000);

export const INTERVAL_ONE_HOUR = (60 * 60 * 1000);
export const INTERVAL_ONE_DAY = (24 * 60 * 60 * 1000);

// Token Refresh
export const TOKEN_REFRESH_INTERVAL = (10 * 60 * 1000);
export const GRANT_COMPONENT_KEY = 'GRANT';
