import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/report`
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getLicenseRegisterReportList: () => `${RESOURCE_ENDPOINT}/license-register-report`,
    getPermitRegisterReportList: () => `${RESOURCE_ENDPOINT}/permit-register-report`,
    getNocRegisterReportList: () => `${RESOURCE_ENDPOINT}/noc-register-report`,
    getRadioactiveMaterialsReportList: () => `${RESOURCE_ENDPOINT}/radioactive-materials-report`,
    getFeeIncomeReportList: () => `${RESOURCE_ENDPOINT}/fee-income-report`,
}

export default class ReportLicenseApi {
    public getExampleReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    };

    public getLicenseRegisterReportList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getLicenseRegisterReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getPermitRegisterReportList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPermitRegisterReportList();
        return HttpService.post(url, payload, params, headers);
    };

    public getNocRegisterReportList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNocRegisterReportList();
        return HttpService.get(url, params, headers);
    };

    public getRadioactiveMaterialsReportList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRadioactiveMaterialsReportList();
        return HttpService.post(url, payload, params, headers);
    };
    
    public getFeeIncomeReportList = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getFeeIncomeReportList();
        return HttpService.post(url, payload, params, headers);
    };
}
