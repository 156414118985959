import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/verification`;
const endpoints = {
    verify: (uuid: any) => `${RESOURCE_ENDPOINT}/verify/${uuid}`,
};

export default class LicensePermitVerificationApi {
    public verify = (uuid: any): AxiosPromise<any> => {
        const url = endpoints.verify(uuid);
        return HttpService.get(url);
    };
}
