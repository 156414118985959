import { StorageService } from "@/services";
import {
    CLEAR_ALL_AUTH_STATE,
    CLEAR_AUTH_STATE,
    LOADED_APPLICANT_PROFILE,
    LOADED_AUTH_STATE,
    LOADED_SCOPE,
    LOADED_TOKEN,
    REMOVE_TOKEN,
    SET_ERROR,
    SET_LOADING
} from './auth.types';

export default (state, action) => {
    switch (action.type) {
        case LOADED_AUTH_STATE:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                isAdmin: action.payload.isAdmin,
                isUser: action.payload.isUser,
                user: action.payload.user,
                userId: action.payload.userId,
                userName: action.payload.userName,
                userType: action.payload.userType,
                profileId: action.payload.profileId,
                profileNameEn: action.payload.profileNameEn,
                profileNameBn: action.payload.profileNameBn,
                profileType: action.payload.profileType,
                profileImage: action.payload.profileImage,
                departmentId: action.payload.departmentId,
                designationId: action.payload.designationId,
                groupIds: action.payload.groupIds,
                groupNameList: action.payload.groupNameList,
                groupCodeList: action.payload.groupCodeList,
                roleIds: action.payload.roleIds,
                roleNameList: action.payload.roleNameList,
                roleCodeList: action.payload.roleCodeList,
                committeeId: action.payload.committeeId,
                committeeName: action.payload.committeeName,
                committeeType: action.payload.committeeType,
                organogramId: action.payload.organogramId,
                organogramIds: action.payload.organogramIds,
                organogramNameEn: action.payload.organogramNameEn,
                organogramNameBn: action.payload.organogramNameBn,
                organizationId: action.payload.organizationId,
                organizationIds: action.payload.organizationIds,
                organizationNameEn: action.payload.organizationNameEn,
                organizationNameBn: action.payload.organizationNameBn,
                instituteOrganizationId: action.payload.instituteOrganizationId,
                employeeId: action.payload.employeeId,
                orgOrganogramDesignationId: action.payload.orgOrganogramDesignationId,
                instituteBaeraInstallationCode: action.payload.instituteBaeraInstallationCode,
                loading: false,
                error: false,
            };

        case LOADED_APPLICANT_PROFILE:
            return {
                ...state,
                applicantProfile: action.payload.applicantProfile
            };

        case LOADED_SCOPE:
                return {
                ...state,
                scopes: action.payload.scopes,
            };

        case LOADED_TOKEN:
                StorageService.setAccessToken(action.payload.accessToken);
                StorageService.setRefreshToken(action.payload.refreshToken);
                return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            };

        case REMOVE_TOKEN:
                StorageService.removeAccessToken();
                StorageService.removeRefreshToken();
                return {
                ...state,
                isAuthenticated: false,
                isAdmin: false,
                isUser: false,
                accessToken: null,
                refreshToken: null,
            };

        case CLEAR_AUTH_STATE:
            return {
                ...state,
                isAuthenticated: false,
                isAdmin: false,
                isUser: false,
                user: null,
                userId: null,
                scopes: null,
                loading: false,
                error: null,
            };

        case CLEAR_ALL_AUTH_STATE:
            return {
                ...state,
                isAuthenticated: false,
                isAdmin: false,
                isUser: false,
                user: null,
                userId: null,
                userName: null,
                userType: null,
                profileId: null,
                profileNameEn: null,
                profileNameBn: null,
                profileType: null,
                profileImage: null,
                departmentId: null,
                designationId: null,
                groupIds: null,
                groupNameList: null,
                groupCodeList: null,
                committeeId: null,
                committeeName: null,
                committeeType: null,
                roleIds: null,
                roleNameList: null,
                roleCodeList: null,
                organogramId: null,
                organogramIds: null,
                organogramNameEn: null,
                organogramNameBn: null,
                organizationId: null,
                organizationIds: null,
                organizationNameEn: null,
                organizationNameBn: null,
                instituteOrganizationId: null,
                employeeId: null,
                orgOrganogramDesignationId: null,
                instituteBaeraInstallationCode: null,
                scopes: null,
                loading: false,
                error: null,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            };

        case SET_ERROR:
            return {
                ...state,
                error: action.payload.error,
            };

        default:
            return state;
    }
};
