import { CONSTANT_CONFIG } from "@/constants";
import { HttpService } from "@/services/http.services";
import { AxiosPromise } from "axios";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.LICENSE_SERVER_PREFIX}/license-permit`;
const endpoints = {
    selectedLicenseExpiryCheck: () => `${RESOURCE_ENDPOINT}/expiry-check`,
};

export default class LicensePermitApi {
    public selectedLicenseExpiryCheck = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.selectedLicenseExpiryCheck();
        return HttpService.post(url, payload, params, headers);
    };
}
